import React from 'react'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../components/ParagraphUnderPhoto/styles.css'
import Index from '../../../../content/accordion/sluzby'

import { graphql } from 'gatsby'
import {
  SingleAccordion,
  Description,
} from '../../../components/Services/ServiceOverview/SingleAccordion'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components'

export const Zahranici = ({ data: { page }, pageContext }) => {
  const { frontmatter } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
        metaCrumb={crumbs}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <Breadcrumb crumbs={crumbs} crumbLabel={frontmatter.title} />
        <div className="Paragraph--Wrapper container">
          <p>
            Jsou Vašimi obchodními partnery zahraniční společnosti nebo dokonce
            firmy v zemích mimo EU? Pak vás bude trápit DPH a v některých
            případech také clo. Neváhejte se na nás obrátit, pomůžeme také se
            zpracováním dat pro Intrastat – od registrace až po samotné reporty.
          </p>
          <SingleAccordion
            items={Index.zahranici.links.map(item => ({
              title: item.name,
              description: <Description {...item} />,
            }))}
          />
        </div>
      </Layout>
    </>
  )
}
export default Zahranici

export const pageQuery = graphql`
  query Zahranici {
    page: markdownRemark(frontmatter: { slug: { eq: "sluzby/zahranici" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
